<template>
  <b-container class="mt-4">
    <b-form @submit="filterCompetitions">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="'Yarışma Grupları'" class="mr-2 text-white"></label>
          <b-button
            variant="dark"
            class="ml-2"
            :to="{
              name: 'CompetitionDetail',
              params: { id: $route.params.id },
            }"
          >
            Geri
          </b-button>
          <b-button variant="primary" class="ml-4" to="/competitions">
            Yarışmalar
          </b-button>
          <b-button
            variant="dark"
            class="ml-4"
            :to="{ name: 'Matches', params: { id: $route.params.id } }"
          >
            Karşılaşma Listesi
          </b-button>
          <b-button class="ml-4" variant="primary"  :to="{
              name: 'FederationIndividualGroupRequests',
              params: { id: $route.params.id },
            }">Ferdi Grup İstekleri</b-button>
          <b-button variant="danger" class="float-right ml-4" @click="printParticipantList">
            PDF Al
          </b-button>

          <download-excel
            class="btn btn-success float-right ml-4"
            :fetch="getData"
            :fields="
             reports.competitionParticipant.fields.reduce((obj, e) => {
                obj[e] = e;
                return obj;
              }, {})
            "
            :worksheet=" reports.competitionParticipant.title"
            name="filename.xls"
          >
            Excel al
          </download-excel>
        </template>
        <b-row>
          <b-col>
            <b-form-input
              placeholder="Ad / Soyad"
              v-model="competitionGroupFilter"
            >
            </b-form-input>
          </b-col>
          <b-col>
            <b-button variant="secondary" type="submit">Gönder</b-button>
          </b-col>
        </b-row>
        <hr />

        <PMSTable
          :tableFields="competitionGroupFields"
          :tableItems="competitionGroups"
          :editable="true"
          :erasable="true"
          hasAction="group"
          :pageCount="getCompetitionGroupTotalPageCount"
          @onPageChanged="onPageChanged"
          @openDetail="openDetail"
          deleteMessage="Yarışma grubunu silmek istediğinizden emin misiniz?"
          @groupDetail="groupDetail"
          @editClick="editClick"
          @multipleDeleteAction="deleteMultiple"
        ></PMSTable>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import PMSTable from "@/components/tables/GeneralTable.vue";
import { mapGetters } from "vuex";
import reportHelper from "@/store/helpers/PMSReportHelper";
import reports from "../../../store/constants/reports";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    PMSTable,
    "download-excel": JsonExcel,
  },
  computed: {
    ...mapGetters([
      "getCompetitionGroups",
      "getCompetitionGroupFilter",
      "getCompetitionGroupTotalPageCount",
      "getSingleCompetition",
    ]),
    competitionGroups: {
      get() {
        return this.getCompetitionGroups.map((e) => {
          e.personels = [];
          e.personels.push(e.personel1);
          if (e.personelId2) e.personels.push(e.personel2);
          if (e.personelId3) e.personels.push(e.personel3);
          if (e.personelId4) e.personels.push(e.personel4);
          return e;
        });
      },
    },
    competitionGroupFilter: {
      get() {
        return this.getCompetitionGroupFilter;
      },
      set(value) {
        this.$store.commit("updateCompetitionGroupFilter", value);
      },
    },
  },
  created() {
    this.$store.dispatch("initCompetitionGroups", {
      competitionId: this.$route.params.id,
    });
    this.$store.dispatch("initSingleCompetition", this.$route.params.id);
    this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
  },
  data() {
    return {
      reports,
      competitionGroupFields: [
        {
          key: "selected",
          label: "Seç",
        },
        {
          key: "id",
          label: "No",
        },
        {
          key: "clubregion",
          label: "Bölgesi",
        },
        {
          key: "formOfParticipation",
          label: "Katılım Şekli",
        },
        {
          key: "category",
          label: "Kategoriler",
        },
        {
          key: "names",
          label: "Ad Soyadlar",
        },
        {
          key: "events",
          label: "İşlemler",
        },
      ],
    };
  },
  methods: {
    onPageChanged(value) {
      this.$store.dispatch("initCompetitionGroups", {
        competitionId: this.$route.params.id,
        currentPage: value,
      });
    },
    editClick(index) {
      this.$router.push({
        name: "EditGroup",
        params: {
          competitionId: this.$route.params.id,
          id: this.getCompetitionGroups[index].id,
        },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    groupDetail(id) {
      this.$router.push({
        name: "GroupDetail",
        params: { id: id, competitionId: this.$route.params.id },
      });
    },
    deleteMultiple(indexes) {
      this.$store.dispatch("deleteFederationCompetitionGroup", {
        competitionId: this.$route.params.id,
        items: indexes,
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    filterCompetitions(event) {
      event.preventDefault();
      this.$store.dispatch("initCompetitionGroups", {
        competitionId: this.$route.params.id,
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    openDetail(id) {
      this.$router.push({
        name: "PersonelDetail",
        params: { id: id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    async printParticipantList() {
      reportHelper.generateHTML(
        reports.competitionParticipant.title +
          " \n" +
          this.getSingleCompetition.name +
          " Katılımcı Listesi",
        reports.competitionParticipant.fields,
        await reportHelper.getPdfItemsFromServer(
          reports.competitionParticipant.endpoint,
          { id: this.$route.params.id }
        )
      );
    },

    async getData() {
      const data = await reportHelper.getPdfItemsFromServer(
        reports.competitionParticipant.endpoint,
        { id: this.$route.params.id }
      );
      return data.map((e) => {
        const row = {};
        reports.competitionParticipant.fields.forEach((element, index) => {
          row[element] = e[index];
        });

        return row;
      });
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>