var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-4"},[_c('b-form',{on:{"submit":_vm.filterCompetitions}},[_c('b-card',{attrs:{"header-tag":"header","header-class":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('label',{staticClass:"mr-2 text-white",domProps:{"textContent":_vm._s('Yarışma Grupları')}}),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"dark","to":{
            name: 'CompetitionDetail',
            params: { id: _vm.$route.params.id },
          }}},[_vm._v(" Geri ")]),_c('b-button',{staticClass:"ml-4",attrs:{"variant":"primary","to":"/competitions"}},[_vm._v(" Yarışmalar ")]),_c('b-button',{staticClass:"ml-4",attrs:{"variant":"dark","to":{ name: 'Matches', params: { id: _vm.$route.params.id } }}},[_vm._v(" Karşılaşma Listesi ")]),_c('b-button',{staticClass:"ml-4",attrs:{"variant":"primary","to":{
            name: 'FederationIndividualGroupRequests',
            params: { id: _vm.$route.params.id },
          }}},[_vm._v("Ferdi Grup İstekleri")]),_c('b-button',{staticClass:"float-right ml-4",attrs:{"variant":"danger"},on:{"click":_vm.printParticipantList}},[_vm._v(" PDF Al ")]),_c('download-excel',{staticClass:"btn btn-success float-right ml-4",attrs:{"fetch":_vm.getData,"fields":_vm.reports.competitionParticipant.fields.reduce(function (obj, e) {
              obj[e] = e;
              return obj;
            }, {}),"worksheet":_vm.reports.competitionParticipant.title,"name":"filename.xls"}},[_vm._v(" Excel al ")])]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('b-form-input',{attrs:{"placeholder":"Ad / Soyad"},model:{value:(_vm.competitionGroupFilter),callback:function ($$v) {_vm.competitionGroupFilter=$$v},expression:"competitionGroupFilter"}})],1),_c('b-col',[_c('b-button',{attrs:{"variant":"secondary","type":"submit"}},[_vm._v("Gönder")])],1)],1),_c('hr'),_c('PMSTable',{attrs:{"tableFields":_vm.competitionGroupFields,"tableItems":_vm.competitionGroups,"editable":true,"erasable":true,"hasAction":"group","pageCount":_vm.getCompetitionGroupTotalPageCount,"deleteMessage":"Yarışma grubunu silmek istediğinizden emin misiniz?"},on:{"onPageChanged":_vm.onPageChanged,"openDetail":_vm.openDetail,"groupDetail":_vm.groupDetail,"editClick":_vm.editClick,"multipleDeleteAction":_vm.deleteMultiple}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }